























































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import openDialog from '@/components/wechatEditor/openDialog.vue';
import { materialApi } from '@/services/material-service';
@Component({
  components: {
    openDialog
  }
})
export default class wechatPicture extends Vue {
  public formImage: any = {};
  public form: any = {
    pages: 1,
    size: 10,
    current: 1,
    total: 0
  };
  public loading: Boolean = true;
  private pagination: any = {
    pageSize: 10,
    total: 0,
    pageNum: 1
  };
  private tableData: any[] = [];
  private currentRow: any = null;
  @Inject(materialApi) private materialApi!: materialApi;
  addPicture(): void {
    // console.log(this.$refs.childDialog) //返回的是一个vue对象，所以可以直接调用其方法
    (this.$refs.childDialog as openDialog).upImage();
  }
  public async created(): Promise<void> {
    await this.getList();
  }

  public search() {
    this.getList()
  }
  //分页相关
  public handleSizeChange(size: number) {
    this.form.size = size;
    this.getList();
  }
  public handleCurrentChange(current: number) {
    this.form.current = current;
    this.getList();
  }
  //获取数据源
  public async getList() {
    this.loading = true;
    this.form.type = 1;
    const res = await this.materialApi.getList(this.form);
    this.loading = false;
    let data: any = res.records
    this.formData(data)
    this.form.total = res.total;
    this.form.pages = res.pages;
    this.form.size = res.size;
    this.form.current = res.current;
  }
  public async formData(res: any) {
    let data = res, that = this;
    // 处理时间
    for (var i = 0; i < data.length; i++) {
      // data[i].
      let temp = data[i].createTime.split('T')   //split()方法，以T字符为截断，划分成2块，返回一个数组
      const year = temp[0].slice(0, 4)  //slice()截取字符，开始--结束
      const month = temp[0].slice(5, 7)
      const day = temp[0].slice(8, 10)
      const time_ = temp[1].slice(0, 5)
      data[i].createTime = `${year}-${month}-${day} ${time_}`

    }
    this.tableData = data;
  }
  //删除
  private async handDelete(row: any): Promise<void> {
    console.log(row)
    let param = {
      id: row.id
    }
    try {
      await this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      await this.materialApi.delePic(param);
      this.getList()
      this.$message({
        message: '删除成功',
        type: 'success'
      });
    } catch (e) {
      this.$message({
        type: 'info',
        message: '已取消删除'
      });
    }
  }
  //得到子组件传过来的值
  public getChild(val: any): void {
    this.$nextTick(async () => {
      var formdata = {
        url:val.src,
        type :1
      }
      const res = await this.materialApi.savePic(formdata);
      this.getList()
    })
  }
  // 重置
  public reset() {
    this.formImage = {};
    this.form = {
      pages: 1,
      size: 10,
      current: 1,
      total: 0
    };
    this.getList()
  }
  private mounted() {
    document.onkeydown = (e) => {
      let key = (window.event as any).keyCode;
      //  alert(key)
      if (key == 13) {
        this.search();
      }
    }
  }
}

